export const randomAngle = (floor = 0, ceil = 1) => {
  const randomNumber = Math.floor(Math.random() * (ceil + 1)) + floor;
  return randomNumber < 0 ? 360 + randomNumber : randomNumber;
};

export const randomNumber = (floor = 0, ceil = 1) =>
  Math.floor(Math.random() * (ceil + 1)) + floor;

export function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
