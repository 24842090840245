import React, { useEffect, useRef, useState } from "react";
import ServiceCard from "../components/Homepage/ServiceCard";
import Btn from "../components/Btn";
import {
  bannerDescription,
  services,
  homePageDescriptionTag,
  homePageTitleTag,
  homePageHeroHeading,
} from "../content/homepageContent";
import MetaTags from "../utils/MetaTags";
import { urbaneFinanceDescription } from "../content/uFinancePageContent";
import CircleText from "../components/Homepage/CircleText";
import Footer from "../components/Footer";

const MobileHomepage = () => {
  const [changeStyle, setChangeStyle] = useState(false);
  const [reload, setReload] = useState(false);
  const [serviceCardsElem, setServiceCardsElem] = useState(null);
  const serviceCardsRef = useRef();

  return (
    <>
      <div className="mobile-homepage-container">
        <MetaTags
          title={homePageTitleTag}
          description={homePageDescriptionTag}
        />

        <div className="hero-wrapper">
          <div className="hero-container">
            <div className="hero-text-container">
              <h1 className="hero-heading heading">{homePageHeroHeading}</h1>
              <p className="hero-description">{bannerDescription}</p>
            </div>
            <div className="hero-btn-container">
              <Btn text="Contact Us" colour={"#4696f3"} link="contacts" />
            </div>
          </div>
        </div>

        <h2 className="home-services-heading">Services</h2>

        <div className="home-services-wrapper">
          <div className="home-services-container">
            {/* <h3 className="home-section-heading">
          We develop <HeadingUnderline />
        </h3> */}

            <div className="service-cards-wrapper">
              <div className="card-toggle-btn"></div>
              <div className="service-cards-container">
                {services.map((service) => (
                  <ServiceCard
                    key={service.service + "bmx"}
                    serviceObj={service}
                    changeStyle={changeStyle}
                    setChangeStyle={setChangeStyle}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <h2 className="home-products-heading">Products</h2>
        <div className="home-uf-container">
          <div className="home-uf-text-container">
            <div className="home-uf-heading">Urbane Finance</div>
            <div className="home-uf-description">
              {urbaneFinanceDescription}
            </div>
            <Btn
              text="Find Out More"
              colour={"#4696f3"}
              link="urbane-finance"
            />
          </div>
        </div>
        <div className="page-spacer"></div>
      </div>
      <Footer />
    </>
  );
};

export default MobileHomepage;
