import React, { useEffect, useRef, useState } from "react";
import ServiceCard from "../components/Homepage/ServiceCard";
import Btn from "../components/Btn";

import {
  bannerDescription,
  services,
  homePageDescriptionTag,
  homePageTitleTag,
  homePageHeroHeading,
} from "../content/homepageContent";
import MetaTags from "../utils/MetaTags";
import { urbaneFinanceDescription } from "../content/uFinancePageContent";
import CircleText from "../components/Homepage/CircleText";

const Homepage = ({ ScrollTrigger, gsap }) => {
  const [changeStyle, setChangeStyle] = useState(false);
  const [reload, setReload] = useState(false);
  const [serviceCardsElem, setServiceCardsElem] = useState(null);
  const serviceCardsRef = useRef();

  const winH = window.innerHeight;
  const winW = window.innerWidth;

  const homeWrapperStyles = () => {
    // if (serviceCardsElem) console.log(serviceCardsElem.clientHeight);
    return {
      height: 1.2 * winH + 100,
      // 0.5 * winH + (serviceCardsElem ? serviceCardsElem.clientHeight : 0),
    };
  };

  const toggleRed = () => {
    const heading = document.querySelector(".home-services-heading");
    heading && heading.classList.add("off");
    heading && heading.classList.remove("on");
  };

  console.log(winH, winW);

  const reloadPage = () => setReload((prev) => !prev);

  function debounce(fn, ms) {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  useEffect(() => {
    setServiceCardsElem(serviceCardsRef.current);
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // window.addEventListener("resize", () => {
    //   ScrollTrigger.refresh();
    // });

    // window.addEventListener("scroll", () => {
    //   ScrollTrigger.refresh();
    // });

    const tl = gsap.timeline();
    const serviceCardsH = document.querySelector(
      ".home-services-container"
    ).clientHeight;

    tl.to(".circle-text-container", {
      opacity: 0,
      scrollTrigger: {
        // animation: tl,
        trigger: ".scroll-element",
        start: `${winH}px 100%`,
        end: `${winH + 10}px 100%`,
        scrub: 0.6,
        // markers: true,
      },
    });

    // HERO ANIMATE OUT
    tl.fromTo(
      ".hero-container",
      {
        scale: 1,
      },
      {
        scale: 0.4,
        opacity: 0,
        y: winH * 0.5,
        scrollTrigger: {
          // animation: tl,
          trigger: ".scroll-element",
          start: `${winH}px 100%`,
          end: `${winH + 60}px 100%`,
          scrub: 0.6,
        },
      }
    );

    // SERVICES HEADING ANIMATION
    tl.to(".home-services-heading", {
      opacity: 1,
      display: "initial",
      // y: -480,
      scrollTrigger: {
        // animation: tl,
        trigger: ".scroll-element",
        start: `${winH}px 100%`,
        end: `${winH + 60}px 100%`,
        scrub: 0.6,
      },
    });

    // ANIMATING SERVICES WRAPPER FOR SERVICES ENTER ANIMATION
    tl.fromTo(
      ".home-services-wrapper",
      {
        opacity: 1,
        scale: 3,
        // display: "initial",
        y: 1.2 * winH,
      },
      {
        y: -winH * 0.2,
        scale: 1,
        opacity: 1,
        scrollTrigger: {
          // animation: tl,
          trigger: ".scroll-element",
          start: `${winH + 0}px 100%`,
          end: `${winH + 60}px 100%`,
          scrub: 0.6,
          // markers: true,
        },
      }
    );

    // SCROLLING SERVICES ANIMATION
    tl.to(".home-services-container", {
      y: -0.8 * serviceCardsH,
      opacity: 1,
      scrollTrigger: {
        // animation: tl,
        trigger: ".scroll-element",
        start: `${winH + 60}px 100%`,
        end: `${winH * 1.5}px 100%`,
        scrub: 0.6,
      },
    });

    // SERVICES LEAVE ANIMATION
    tl.fromTo(
      ".home-services-container > *",
      {
        scale: 1,
      },
      {
        y: -winH * 0.8,
        scale: 0.5,
        opacity: 0,
        scrollTrigger: {
          // animation: tl,
          trigger: ".scroll-element",
          start: `${winH * 1.5 - 30}px 100%`,
          end: `${winH * 1.5 + 80}px 100%`,
          // end: `${winH * 1.5}px 100%`,
          scrub: 0.6,
          // markers: true,
        },
      }
    );

    // SERVICES HEADING ANIMATE OUT
    tl.to(".home-services-heading", {
      opacity: 0,
      // display: "initial",
      scrollTrigger: {
        trigger: ".scroll-element",
        // start: `${60 + serviceCardsH / 1.5}px 90%`,
        start: `${winH * 1.5 - 30}px 100%`,
        end: `${winH * 1.5 + 20}px 99%`,
        scrub: 0.6,
      },
    });

    // PRODUCT HEADING ANIMATE IN
    tl.to(".home-products-heading", {
      opacity: 2,
      display: "initial",
      scrollTrigger: {
        trigger: ".scroll-element",
        start: `${winH * 1.5 - 30}px 100%`,
        end: `${winH * 1.5 + 60}px 100%`,
        scrub: 0.6,
      },
    });

    // PRODUCTS ENTER ANIMATION
    tl.fromTo(
      ".home-uf-container > *",
      {
        y: winH,
        // scale: winW > 800 ? 2.5 : 1.4,
        scale: 7,
        opacity: 0,
      },
      {
        y: -50,
        opacity: 1,
        scale: 1,
        display: "initial",
        scrollTrigger: {
          trigger: ".scroll-element",
          start: `${winH * 1.5 - 10}px 100%`,
          end: `+=35`,
          scrub: 0.6,
          // markers: true,
        },
      }
    );
  }, []);

  // window.addEventListener("scroll", () => {
  //   if (serviceCardsElem)
  //     console.log(serviceCardsElem.clientHeight, window.scrollY);
  // });

  return (
    <div className="homepage-container">
      <MetaTags title={homePageTitleTag} description={homePageDescriptionTag} />

      <CircleText text="Proudly-South-African. " handler={() => {}} />

      <div
        style={{
          height: serviceCardsElem ? winH * 1.5 + 80 : 2000,
        }}
        className="scroll-element"
      ></div>

      <div className="hero-wrapper">
        <div className="hero-container">
          <div className="hero-text-container">
            <h1 className="hero-heading heading">{homePageHeroHeading}</h1>
            <p className="hero-description">{bannerDescription}</p>
          </div>
          <div className="hero-btn-container">
            <Btn text="Contact Us" colour={"#4696f3"} link="contacts" />
          </div>
        </div>
      </div>

      <h2 className="home-services-heading">Services</h2>

      <div style={homeWrapperStyles()} className="home-services-wrapper">
        <div ref={serviceCardsRef} className="home-services-container">
          {/* <h3 className="home-section-heading">
          We develop <HeadingUnderline />
        </h3> */}

          <div className="service-cards-wrapper">
            <div className="card-toggle-btn"></div>
            <div className="service-cards-container">
              {services.map((service) => (
                <ServiceCard
                  key={service.service + "bmx"}
                  serviceObj={service}
                  changeStyle={changeStyle}
                  setChangeStyle={setChangeStyle}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <h2 className="home-products-heading">Products</h2>
      <div className="home-uf-container">
        <div className="home-uf-text-container">
          <div className="home-uf-heading">Urbane Finance</div>
          <div className="home-uf-description">{urbaneFinanceDescription}</div>
          <Btn text="Find Out More" colour={"#4696f3"} link="urbane-finance" />
        </div>
      </div>
      <div className="page-spacer"></div>
    </div>
  );
};

export default Homepage;
