import pcbImage from "../images/es.png";
import pcImage from "../images/desktop.png";
import webImage from "../images/web.png";
import mobileImage from "../images/mobile.png";

// export const bannerDescription =
//   "We cater to your software needs to help you make that digital footprint you require. Our services include the development of websites, mobile applications, desktop applications, and much more. Being an upcoming organization, Urbane Software can help you realize your needs for very affordable prices. Contact us to get a quote. We are looking forward to working with you.";
export const bannerDescription =
  "We cater to your software needs to help you make that digital footprint you require. Our services include the development of websites, mobile applications, desktop applications, and much more.";

export const services = [
  {
    service: "Desktop Applications",
    description:
      "Be it a Windows application, Linux application or even a MacOS Application that you need, we can help you realize that dream.",
    icon: "pc",
    // image: pcImage,
    image: pcImage,
  },
  {
    service: "Embedded Software",
    description:
      "Are you starting off your electronic project and need Embedded firmware, Urbane Software can produce that low level logic for you.",
    icon: "pcb",
    // image: pcbImage,
    image: pcbImage,
  },
  {
    service: "Mobile Applications",
    description:
      "Seeking for an Android application or an iOS application, Urbane Software can help you develop both.",
    icon: "mobile",
    // image: mobileImage,
    image: mobileImage,
  },
  {
    service: "Websites and Web Applications",
    description:
      "We can assist you in building or enhancing your digital footprint, enabling your customers access to you and your services easily through the web.",
    icon: "web",
    // image: webImage,
    image: webImage,
  },
];

export const homeUrbaneFinanceDescription =
  "Urbane Finance is a proprietary software that allows you and/or your institution to generate Income and Expenditure statements as well as other financial documents programmatically to simplify and streamline the way you handle your administrative financial tasks.";

export const homePageTitleTag = "Urbane Software";
export const homePageHeroHeading = "Your One Stop Software Shop";
export const homePageDescriptionTag =
  "At Urbane Software craft software solutions as a service. Our main areas of expertise are Web Development, Mobile App Development, Desktop Software as well as Embedded Systems. We're your one stop software shop that is always ready to help.";
