import React from "react";
import { mainColour } from "../../utils/variables";

const FormLoadingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.875"
      height="34.382"
      viewBox="0 0 34.875 34.382"
    >
      <path
        id="Icon_awesome-circle-notch"
        fill={mainColour}
        data-name="Icon awesome-circle-notch"
        d="M20.25,2.746V3.917a1.688,1.688,0,0,0,1.244,1.622,12.937,12.937,0,1,1-6.987,0A1.688,1.688,0,0,0,15.75,3.917V2.747a1.689,1.689,0,0,0-2.113-1.634,17.438,17.438,0,1,0,8.707,0A1.685,1.685,0,0,0,20.25,2.746Z"
        transform="translate(-0.562 -1.055)"
      />
    </svg>
  );
};

export default FormLoadingIcon;
