// ICON VARIABLES
export const computerIcon = "computer";
export const envelopeIcon = "envelope";
export const earthIcon = "web";
export const pcbIcon = "pcb";
export const smartphoneIcon = "phone";
export const instaIcon = "insta";
export const callIcon = "call";
export const emailIcon = "email";

// COLOUR VARIABLES
export const mainColour = "#fff";
export const mainColourLight = "#000";
export const themeColour = "#71b1eb";
