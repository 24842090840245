import React from "react";
import Icon from "../Icon";
import PremiumTag from "../PremiumTag";

const ContactCard = ({ icon, text, premium }) => {
  return (
    <div className="contact-card-container">
      <Icon icon={icon} />
      <h4 className="contact-card-text">
        {text}
        {premium && <PremiumTag />}
      </h4>
    </div>
  );
};

export default ContactCard;
