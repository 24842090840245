import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Homepage from "../pages/Homepage";
import MobileHomepage from "../pages/MobileHomepage";

const HomeRouter = ({ gsap, ScrollTrigger }) => {
  const [winW, setWinW] = useState(window.innerWidth);

  function debounce(fn, ms) {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }
  const changeWinW = () => {
    winW !== window.innerWidth && window.location.reload();
    setWinW(window.innerWidth);
  };

  useEffect(() => {
    setWinW(window.innerWidth);
    window.addEventListener("resize", debounce(changeWinW, 500));
  }, []);

  if (winW > 600) {
    return <Homepage gsap={gsap} ScrollTrigger={ScrollTrigger} />;
  }

  return <MobileHomepage />;
};

export default HomeRouter;
