export const contactDetails = [
  { icon: "email", text: "info@urbanesoftware.co.za", isPremium: false },
  { icon: "insta", text: "UrbaneSoftware_za", isPremium: false },
  { icon: "call", text: "xxx-xxx-xxxx", isPremium: true },
];

// EMAIL JS CREDENTIALS
export const email_serviceId = "service_nk1ga6d";
export const email_templateId = "template_h1dpz1c";
export const email_userId = "user_fkQ4lsjeViCaKOpTLFGQc";

export const contactPageTitleTag = "Contacts - Urbane Software";
export const contactPageDescriptionTag =
  "Contact us at info@urbanesoftware.co.za or on our Instagram UrbaneSoftware_za. Alternatively you can fill in our form for any enquiries, comments or concerns. We'd love to hear from you.";
