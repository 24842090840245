import React from "react";
import Icon from "../Icon";
import PremiumTag from "../PremiumTag";

const FeatureCard = ({ feature, premium }) => {
  return (
    <div className="feature-card-container">
      <div className="feature-icon-point"></div>
      <p className="feature-card-text">
        {feature}
        {premium && <PremiumTag />}
      </p>
    </div>
  );
};

export default FeatureCard;
