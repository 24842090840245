import React from "react";

const HeadingUnderline = () => {
  return (
    <div className="heading-underline-container">
      <div className="heading-underline-1"></div>
      <div className="heading-underline-2"></div>
      <div className="heading-underline-3"></div>
    </div>
  );
};

export default HeadingUnderline;
