import React, { useEffect, useState } from "react";
import ContactCard from "../components/ContactsPage/ContactCard";
import { useForm } from "react-hook-form";
import {
  contactDetails,
  email_serviceId,
  email_templateId,
  email_userId,
  contactPageTitleTag,
  contactPageDescriptionTag,
} from "../content/contactPageContent";
import FormLoadingIcon from "../components/ContactsPage/FormLoadingIcon";
import emailjs from "emailjs-com";
import FormErrorMsg from "../components/ContactsPage/FormErrorMsg";
import MetaTags from "../utils/MetaTags";
import HeadingUnderline from "../components/HeadingUnderline";
import Footer from "../components/Footer";

const ContactsPage = () => {
  const { register, handleSubmit, errors } = useForm();
  const [messageRequired, setMessageRequired] = useState(false);

  const [{ loading, success }, setLoading] = useState({
    loading: false,
    success: null,
  });

  const submitForm = (data, e) => {
    setLoading({ loading: true, success: null });
    emailjs
      .sendForm(email_serviceId, email_templateId, e.target, email_userId)
      .then(
        (result) => {
          e.target.name.value = "";
          e.target.email.value = "";
          e.target.message.value = "";
          setLoading({ loading: false, success: true });
        },
        (error) => {
          console.log(error);
          setLoading({ loading: false, success: false });
        }
      );
  };

  useEffect(() => window.scrollTo(0, 0), []);

  const handleMessageRequired = (selectedField) => {
    selectedField === "license" && messageRequired && setMessageRequired(false);
    selectedField !== "license" && !messageRequired && setMessageRequired(true);
  };

  return (
    <>
      <div className="contacts-page-container page-container">
        <MetaTags
          title={contactPageTitleTag}
          description={contactPageDescriptionTag}
        />
        <h1 className="contacts-heading heading">Contacts</h1>

        <div className="contacts-content">
          <div className="contact-cards-container">
            {contactDetails.map((contact) => (
              <div
                key={contact.text + contact.icon}
                className="contact-card-wrapper"
              >
                <ContactCard
                  icon={contact.icon}
                  text={contact.text}
                  premium={contact.isPremium}
                />
              </div>
            ))}
          </div>
          <div className="contact-form-container">
            <form onSubmit={handleSubmit(submitForm)} className="contact-form">
              <input
                type="text"
                name="email"
                placeholder="email"
                ref={register({ required: true })}
                className="contact-form-input"
              />

              {errors.email && (
                <FormErrorMsg msg="please enter a valid email address." />
              )}

              <input
                type="text"
                name="name"
                placeholder="name/institution name"
                ref={register({ required: true })}
                className="contact-form-input"
              />
              {errors.name && <FormErrorMsg msg="please enter a valid name." />}
              <select
                name="enquiry"
                ref={register({ required: true })}
                className="contact-form-select"
                onChange={(e) => handleMessageRequired(e.target.value)}
              >
                <option value="license">
                  Interested in Urbane Finance License
                </option>
                <option value="enquiry">Enquiry</option>
                <option value="issue">Report An Issue</option>
              </select>

              <textarea
                name="message"
                placeholder={"message" + (messageRequired ? "" : " (optional)")}
                ref={register({ required: messageRequired })}
                className="contact-form-textarea"
              ></textarea>
              {errors.message && <FormErrorMsg msg="please enter a message." />}

              <button type="submit" className="contact-form-submit-btn">
                {loading ? (
                  <>
                    <FormLoadingIcon />
                    <FormLoadingIcon />
                    <FormLoadingIcon />
                  </>
                ) : success === null ? (
                  "Send"
                ) : success ? (
                  "Sent, Send Another"
                ) : (
                  "Failed, Try Again"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactsPage;
