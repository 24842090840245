import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import logo from "../images/logo6.png";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 5 && setScrolled(true);
      window.scrollY <= 3 && setScrolled(false);
    });
  }, []);

  const headerItems = [
    // { text: "home", link: "" },
    { text: "contacts", link: "/contacts" },
    { text: "urbane finance", link: "/urbane-finance" },
  ];
  return (
    <div className={"header-container" + (scrolled ? " header-fixed" : "")}>
      <Link to="">
        <img className="logo" src={logo} alt="logo" />
      </Link>
      <div className="header-nav-items">
        {headerItems.map((item) => (
          <Link key={item.link + item.link + "ds"} to={item.link}>
            <h4 className="header-link">{item.text}</h4>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Header;
