import React, { useEffect } from "react";
import Btn from "../components/Btn";
import FeatureCard from "../components/UFPage/FeatureCard";
import ufLogo from "../images/uf.png";
import {
  linkTo32Bit,
  linkTo64Bit,
  linkToInstallManual,
  linkToUserManual,
  ufFeatures,
  urbaneFinanceDescription,
  ufPageTitleTag,
  ufPageDescriptionTag,
} from "../content/uFinancePageContent";
import MetaTags from "../utils/MetaTags";
import HeadingUnderline from "../components/HeadingUnderline";
import Footer from "../components/Footer";

const UFinancePage = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <div className="urbane-finance-page page-container">
        <MetaTags title={ufPageTitleTag} description={ufPageDescriptionTag} />
        <h1 className="uf-heading heading">Urbane Finance</h1>
        <p className="uf-description">{urbaneFinanceDescription}</p>

        <div className="uf-banner-container">
          {/* <img
          src={ufLogo}
          alt="urbane-finance-logo"
          className="ufinance-image"
        /> */}
          <div className="uf-download-btns-container">
            <a href={linkTo64Bit}>
              <button className="uf-download-btn uf-download-btn-64">
                Download (64 Bit)
              </button>
            </a>
            <a href={linkTo32Bit}>
              <button className="uf-download-btn">Download (32 Bit)</button>
            </a>
          </div>
        </div>
        <div className="uf-content">
          <div className="features-heading-container">
            <h3 className="uf-section-heading">
              Features
              <HeadingUnderline />
            </h3>
          </div>

          <div className="uf-features-container">
            {ufFeatures.map((feature) => (
              <div className="feature-card-container">
                <FeatureCard
                  feature={feature.feature}
                  premium={feature.isPremium}
                />
              </div>
            ))}
          </div>

          <div className="uf-get-license-btn-container">
            <Btn text="Get License" link="contacts" />
          </div>
        </div>
        <div className="uf-documentation-wrapper">
          <div className="uf-documentation-container">
            <h2 className="uf-documentation-heading">Documentation</h2>
            <a href={linkToInstallManual} className="documentation-link">
              Urbane Finance Installation Manual
            </a>
            <a href={linkToUserManual} className="documentation-link">
              Urbane Finance User Manual
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UFinancePage;
