import React, { useState } from "react";

const ServiceCard = ({ serviceObj, changeStyle, setChangeStyle }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { service, image, description } = serviceObj;
  // const styleObj = { filter: `invert(${changeStyle ? "1" : "0"})` };

  return (
    // <div className="service-card-wrapper">
    <div
      onClick={() => setChangeStyle((prev) => !prev)}
      className="service-card-container"
    >
      {/* <img
        src="https://i.dlpng.com/static/png/6511674_preview.png"
        alt={service}
        className="service-card-image"
      /> */}
      <img
        onLoad={() => setImageLoaded(true)}
        src={image}
        alt={service}
        className={"service-card-image" + (imageLoaded ? " image-loaded" : "")}
      />
      <div className="service-card-text">
        <h4 className="service-card-heading">{service}</h4>
        <p className="service-card-description">{description}</p>
      </div>
    </div>
    // </div>
  );
};

export default ServiceCard;
