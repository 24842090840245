import React from "react";
import { emailIcon, instaIcon } from "../utils/variables";
import Icon from "./Icon";

const Footer = () => {
  return (
    <div className="footer-container">
      {/* <div className="footer-icons"> */}
      {/* <Icon icon={instaIcon} /> */}
      {/* <Icon icon={emailIcon} /> */}
      {/* </div> */}
      <div className="footer-text">
        {"© " + "Urbane Software " + new Date().getFullYear()}
      </div>
    </div>
  );
};

export default Footer;
