import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import ContactsPage from "./pages/ContactsPage";
import UFinancePage from "./pages/UFinancePage";
import BallAnimation from "./components/BallAnimation";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import HomeRouter from "./components/HomeRouter";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

const App = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  return (
    <div className="app-wrapper">
      {/* <BallAnimation /> */}
      <Router>
        <Header />
        <div className="app-container">
          <div className="app-pages">
            <Switch>
              <Route exact path="/contacts" component={ContactsPage} />
              <Route exact path="/urbane-finance" component={UFinancePage} />
              <Route
                exact
                path=""
                render={() => (
                  <HomeRouter gsap={gsap} ScrollTrigger={ScrollTrigger} />
                )}
              />
              {/* <Route path="" component={MobileHomepage} /> */}
              {/* <Route path="" component={Homepage} /> */}
            </Switch>
          </div>
          {/* <Footer /> */}
        </div>
      </Router>
    </div>
  );
};

export default App;
