import React from "react";
import {
  callIcon,
  computerIcon,
  earthIcon,
  emailIcon,
  envelopeIcon,
  instaIcon,
  pcbIcon,
  smartphoneIcon,
  mainColour,
  mainColourLight,
} from "../utils/variables";

const Icon = ({ icon, colour }) => {
  const fillColour = colour ? mainColour : mainColourLight;
  const pcbColor = mainColour;

  if (icon === computerIcon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="24"
        viewBox="0 0 36 24"
      >
        <path
          id="Icon_material-computer"
          fill={fillColour}
          data-name="Icon material-computer"
          d="M30,27a3,3,0,0,0,2.985-3L33,9a3.009,3.009,0,0,0-3-3H6A3.009,3.009,0,0,0,3,9V24a3.009,3.009,0,0,0,3,3H0v3H36V27ZM6,9H30V24H6Z"
          transform="translate(0 -6)"
        />
      </svg>
    );
  } else if (icon === envelopeIcon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.114"
        height="25.585"
        viewBox="0 0 34.114 25.585"
      >
        <path
          id="Icon_awesome-envelope"
          fill={fillColour}
          data-name="Icon awesome-envelope"
          d="M33.468,12.948a.4.4,0,0,1,.646.313V26.887a3.2,3.2,0,0,1-3.2,3.2H3.2a3.2,3.2,0,0,1-3.2-3.2V13.268a.4.4,0,0,1,.646-.313c1.492,1.159,3.471,2.632,10.267,7.569,1.406,1.026,3.778,3.185,6.143,3.172,2.379.02,4.8-2.185,6.15-3.172C30,15.587,31.975,14.108,33.468,12.948ZM17.057,21.557c1.546.027,3.771-1.946,4.891-2.758,8.842-6.416,9.515-6.976,11.553-8.575a1.594,1.594,0,0,0,.613-1.259V7.7a3.2,3.2,0,0,0-3.2-3.2H3.2A3.2,3.2,0,0,0,0,7.7V8.964a1.6,1.6,0,0,0,.613,1.259c2.039,1.592,2.712,2.159,11.553,8.575C13.286,19.611,15.511,21.584,17.057,21.557Z"
          transform="translate(0 -4.5)"
        />
      </svg>
    );
  } else if (icon === earthIcon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30.849"
        height="30.849"
        viewBox="0 0 33.849 33.849"
      >
        <path
          id="Icon_metro-earth"
          fill={fillColour}
          data-name="Icon metro-earth"
          d="M17.995,1.928A15.424,15.424,0,1,0,33.419,17.352,15.424,15.424,0,0,0,17.995,1.928Zm0,28.921a13.451,13.451,0,0,1-5.339-1.1l7.024-7.9a.964.964,0,0,0,.243-.64V18.316a.964.964,0,0,0-.964-.964c-3.4,0-7-3.539-7.031-3.574a.964.964,0,0,0-.682-.282H7.391a.964.964,0,0,0-.964.964v5.784a.964.964,0,0,0,.533.862l3.323,1.662v5.66A13.5,13.5,0,0,1,5.8,11.568H9.319A.964.964,0,0,0,10,11.286L13.857,7.43a.964.964,0,0,0,.282-.682V4.416a13.534,13.534,0,0,1,9.769.8c-.125.105-.246.216-.363.332a5.785,5.785,0,0,0,4.086,9.875q.143,0,.287-.007a22.638,22.638,0,0,1-.253,11.217.961.961,0,0,0-.025.157,13.454,13.454,0,0,1-9.644,4.057Z"
          transform="translate(-1.071 -1.928)"
        />
      </svg>
    );
  } else if (icon === pcbIcon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="46"
        viewBox="0 0 79 46"
      >
        <g
          id="Group_63"
          fill={fillColour}
          data-name="Group 63"
          transform="translate(-17 -254)"
        >
          <g id="Group_57" data-name="Group 57">
            <rect
              id="Rectangle_18"
              data-name="Rectangle 18"
              width="79"
              height="46"
              rx="6"
              transform="translate(17 254)"
            />
          </g>
          <rect
            id="Rectangle_19"
            data-name="Rectangle 19"
            width="1"
            height="3"
            rx="0.5"
            transform="translate(27 280)"
            fill={pcbColor}
          />
          <rect
            id="Rectangle_29"
            data-name="Rectangle 29"
            width="1"
            height="3"
            rx="0.5"
            transform="translate(29 280)"
            fill={pcbColor}
          />
          <rect
            id="Rectangle_30"
            data-name="Rectangle 30"
            width="1"
            height="3"
            rx="0.5"
            transform="translate(31 280)"
            fill={pcbColor}
          />
          <rect
            id="Rectangle_31"
            data-name="Rectangle 31"
            width="1"
            height="3"
            rx="0.5"
            transform="translate(33 280)"
            fill={pcbColor}
          />
          <rect
            id="Rectangle_32"
            data-name="Rectangle 32"
            width="1"
            height="3"
            rx="0.5"
            transform="translate(35 280)"
            fill={pcbColor}
          />
          <rect
            id="Rectangle_33"
            data-name="Rectangle 33"
            width="1"
            height="3"
            rx="0.5"
            transform="translate(37 280)"
            fill={pcbColor}
          />
          <rect
            id="Rectangle_34"
            data-name="Rectangle 34"
            width="1"
            height="3"
            rx="0.5"
            transform="translate(39 280)"
            fill={pcbColor}
          />
          <rect
            id="Rectangle_27"
            data-name="Rectangle 27"
            width="10"
            height="13"
            rx="1"
            transform="translate(50 267)"
            fill={pcbColor}
          />
          <g id="Group_56" data-name="Group 56" transform="translate(4 2)">
            <rect
              id="Rectangle_20"
              data-name="Rectangle 20"
              width="3"
              height="3"
              rx="1.5"
              transform="translate(76 260)"
              fill={pcbColor}
            />
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="1"
              height="1"
              rx="0.5"
              transform="translate(77 261)"
            />
          </g>
          <g id="Group_59" data-name="Group 59" transform="translate(12 34)">
            <rect
              id="Rectangle_20-2"
              data-name="Rectangle 20"
              width="2"
              height="2"
              rx="1"
              transform="translate(78 261)"
              fill={pcbColor}
            />
          </g>
          <g id="Group_60" data-name="Group 60" transform="translate(-57 34)">
            <rect
              id="Rectangle_20-3"
              data-name="Rectangle 20"
              width="2"
              height="2"
              rx="1"
              transform="translate(78 261)"
              fill={pcbColor}
            />
          </g>
          <g id="Group_61" data-name="Group 61" transform="translate(-57 -4)">
            <rect
              id="Rectangle_20-4"
              data-name="Rectangle 20"
              width="2"
              height="2"
              rx="1"
              transform="translate(78 261)"
              fill={pcbColor}
            />
          </g>
          <g id="Group_62" data-name="Group 62" transform="translate(13 -4)">
            <rect
              id="Rectangle_20-5"
              data-name="Rectangle 20"
              width="2"
              height="2"
              rx="1"
              transform="translate(78 261)"
              fill={pcbColor}
            />
          </g>
          <path
            id="Path_14"
            data-name="Path 14"
            d="M4342.644,2490.519l-10.307-10.761h-3.386"
            transform="translate(1697.943 5007.25) rotate(-139)"
            fill="none"
            stroke={pcbColor}
            stroke-width="1"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M4342.616,2490.116l-10.285-10.359h-3.379"
            transform="translate(1700.186 5006.928) rotate(-139)"
            fill="none"
            stroke={pcbColor}
            stroke-width="1"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M4342.747,2490.82l-10.384-11.063h-3.412"
            transform="translate(1695.823 5007.544) rotate(-139)"
            fill="none"
            stroke={pcbColor}
            stroke-width="1"
          />
          <path
            id="Path_8"
            data-name="Path 8"
            d="M4343.5,2499.5l-10.951-19.743h-3.6"
            transform="translate(-4269 -2205)"
            fill="none"
            stroke={pcbColor}
            stroke-width="1"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M4346,2501.5l-12.833-21.743h-4.215"
            transform="translate(-4269 -2207)"
            fill="none"
            stroke={pcbColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M4344,2496.877l-11.326-17.12h-3.72"
            transform="translate(4558.845 -1935.461) rotate(124)"
            fill="none"
            stroke={pcbColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_18"
            data-name="Path 18"
            d="M4342.943,2495.781l-10.533-16.024h-3.459"
            transform="translate(4557.348 -1937.201) rotate(124)"
            fill="none"
            stroke={pcbColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <line
            id="Line_3"
            data-name="Line 3"
            x2="32"
            transform="translate(18.5 272.5)"
            fill="none"
            stroke={pcbColor}
            stroke-width="1"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            x2="32"
            transform="translate(18.5 274.5)"
            fill="none"
            stroke={pcbColor}
            stroke-width="1"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            x2="32"
            transform="translate(18.5 276.5)"
            fill="none"
            stroke={pcbColor}
            stroke-width="1"
          />
          <g
            id="Group_58"
            data-name="Group 58"
            transform="translate(300.5 236.617) rotate(90)"
          >
            <line
              id="Line_6"
              data-name="Line 6"
              x2="10"
              transform="translate(17.383 259.5)"
              fill="none"
              stroke={pcbColor}
              stroke-width="1"
            />
            <line
              id="Line_7"
              data-name="Line 7"
              y1="10"
              transform="translate(27.383 259.5)"
              fill="none"
              stroke={pcbColor}
              stroke-linecap="round"
              stroke-width="1"
            />
            <line
              id="Line_8"
              data-name="Line 8"
              x2="10"
              transform="translate(17.383 269.5)"
              fill="none"
              stroke={pcbColor}
              stroke-width="1"
            />
          </g>
        </g>
      </svg>
    );
  } else if (icon === instaIcon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.763"
        height="33.755"
        viewBox="0 0 33.763 33.755"
      >
        <path
          id="Icon_awesome-instagram"
          fill={fillColour}
          data-name="Icon awesome-instagram"
          d="M16.88,10.461a8.654,8.654,0,1,0,8.654,8.654A8.641,8.641,0,0,0,16.88,10.461Zm0,14.281a5.626,5.626,0,1,1,5.626-5.626A5.637,5.637,0,0,1,16.88,24.742ZM27.907,10.107a2.019,2.019,0,1,1-2.019-2.019A2.014,2.014,0,0,1,27.907,10.107Zm5.732,2.049a9.99,9.99,0,0,0-2.727-7.073,10.055,10.055,0,0,0-7.073-2.727C21.053,2.2,12.7,2.2,9.913,2.356A10.041,10.041,0,0,0,2.84,5.075C.859,7.049.249,9.444.113,12.148c-.158,2.787-.158,11.14,0,13.927A9.99,9.99,0,0,0,2.84,33.147a10.068,10.068,0,0,0,7.073,2.727c2.787.158,11.14.158,13.927,0a9.99,9.99,0,0,0,7.073-2.727,10.055,10.055,0,0,0,2.727-7.073c.158-2.787.158-11.132,0-13.919Zm-3.6,16.91a5.7,5.7,0,0,1-3.209,3.209c-2.222.881-7.494.678-9.95.678s-7.735.2-9.95-.678a5.7,5.7,0,0,1-3.209-3.209c-.881-2.222-.678-7.494-.678-9.95s-.2-7.735.678-9.95A5.7,5.7,0,0,1,6.93,5.957c2.222-.881,7.494-.678,9.95-.678s7.735-.2,9.95.678a5.7,5.7,0,0,1,3.209,3.209c.881,2.222.678,7.494.678,9.95S30.92,26.851,30.038,29.065Z"
          transform="translate(0.005 -2.238)"
        />
      </svg>
    );
  } else if (icon === callIcon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        viewBox="0 0 36 36"
      >
        <path
          id="Icon_awesome-phone-alt"
          fill={fillColour}
          data-name="Icon awesome-phone-alt"
          d="M34.973,25.439,27.1,22.064a1.687,1.687,0,0,0-1.969.485L21.641,26.81A26.062,26.062,0,0,1,9.183,14.351l4.261-3.488A1.683,1.683,0,0,0,13.929,8.9L10.554,1.02A1.7,1.7,0,0,0,8.62.043L1.308,1.73A1.688,1.688,0,0,0,0,3.375,32.621,32.621,0,0,0,32.625,36a1.687,1.687,0,0,0,1.645-1.308l1.687-7.313a1.708,1.708,0,0,0-.985-1.941Z"
          transform="translate(0 0)"
        />
      </svg>
    );
  } else if (icon === emailIcon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40.882"
        height="27.597"
        viewBox="0 0 40.882 27.597"
      >
        <path
          id="Icon_zocial-email"
          fill={fillColour}
          data-name="Icon zocial-email"
          d="M.072,29.22V6.512q0-.039.118-.749L13.555,17.2.23,30.009a3.341,3.341,0,0,1-.158-.788ZM1.846,4.186a1.7,1.7,0,0,1,.67-.118H38.51a2.232,2.232,0,0,1,.71.118l-13.4,11.472-1.774,1.419-3.509,2.878-3.509-2.878L15.25,15.659Zm.039,27.36L15.329,18.655l5.2,4.218,5.2-4.218L39.18,31.546a1.893,1.893,0,0,1-.67.118H2.516a1.786,1.786,0,0,1-.631-.118ZM27.511,17.2,40.836,5.763a2.353,2.353,0,0,1,.118.749V29.22a3.022,3.022,0,0,1-.118.788Z"
          transform="translate(-0.072 -4.068)"
        />
      </svg>
    );
  } else if (icon === smartphoneIcon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.218"
        height="56.461"
        viewBox="0 0 28.218 56.461"
      >
        <path
          id="Icon_ionic-ios-phone-portrait"
          data-name="Icon ionic-ios-phone-portrait"
          d="M34.285,2.25H14.3A4.147,4.147,0,0,0,10.125,6.4V54.414a4.27,4.27,0,0,0,4.172,4.3H34.272a4.185,4.185,0,0,0,4.071-4.3V6.4A4.044,4.044,0,0,0,34.285,2.25ZM22.35,5.149h3.781a.5.5,0,1,1,0,1.008H22.35a.5.5,0,1,1,0-1.008ZM24.3,56.821A2.193,2.193,0,1,1,26.5,54.628,2.194,2.194,0,0,1,24.3,56.821Zm11.784-6.175H12.394a.253.253,0,0,1-.252-.252V8.93a.253.253,0,0,1,.252-.252H36.087a.253.253,0,0,1,.252.252V50.393A.253.253,0,0,1,36.087,50.645Z"
          transform="translate(-10.125 -2.25)"
          fill={fillColour}
        />
      </svg>
    );
  } else if (icon === smartphoneIcon) {
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.218"
      height="56.461"
      viewBox="0 0 28.218 56.461"
    >
      <path
        id="Icon_ionic-ios-phone-portrait"
        data-name="Icon ionic-ios-phone-portrait"
        d="M34.285,2.25H14.3A4.147,4.147,0,0,0,10.125,6.4V54.414a4.27,4.27,0,0,0,4.172,4.3H34.272a4.185,4.185,0,0,0,4.071-4.3V6.4A4.044,4.044,0,0,0,34.285,2.25ZM22.35,5.149h3.781a.5.5,0,1,1,0,1.008H22.35a.5.5,0,1,1,0-1.008ZM24.3,56.821A2.193,2.193,0,1,1,26.5,54.628,2.194,2.194,0,0,1,24.3,56.821Zm11.784-6.175H12.394a.253.253,0,0,1-.252-.252V8.93a.253.253,0,0,1,.252-.252H36.087a.253.253,0,0,1,.252.252V50.393A.253.253,0,0,1,36.087,50.645Z"
        transform="translate(-10.125 -2.25)"
        fill={fillColour}
      />
    </svg>;
  } else {
    return <span></span>;
  }
};

export default Icon;
