export const urbaneFinanceDescription =
  "Urbane Finance for Windows 10 allows users to automate generating Income and Expanditure statements, Receipt and Payment statements as well as Bank Reconciliation statements.";

export const ufFeatures = [
  {
    feature:
      "Generate a summary of transactions across specified time periods.",
    isPremium: false,
  },
  {
    feature: "Manage multiple accounts.",
    isPremium: false,
  },
  {
    feature: "Direct support from US team for enquires and issues.",
    isPremium: true,
  },
  {
    feature: "Cloud back up for generated documents.",
    isPremium: true,
  },
  {
    feature:
      "Additional features and software customisations tailored to your needs.",
    isPremium: true,
  },
  {
    feature:
      "Simple UI, clean and intuitive interface to provide a seamless, fluid user experience.",
    isPremium: false,
  },
];

export const ufPageTitleTag = "Urbane Finance - Urbane Software";
export const ufPageDescriptionTag = urbaneFinanceDescription;

export const linkTo64Bit =
  "https://urbanesoftware.co.za/download/urbane-finance-64bit.exe";
export const linkTo32Bit =
  "https://urbanesoftware.co.za/download/urbane-finance-32bit.exe";
export const linkToInstallManual =
  "https://urbanesoftware.co.za/download/installation-manual.pdf";
export const linkToUserManual =
  "https://urbanesoftware.co.za/download/user-manual.pdf";
