import React from "react";

const FormErrorMsg = ({ msg }) => {
  return (
    <div className="form-error-msg-container">
      <p className="form-error-msg">{msg}</p>
    </div>
  );
};

export default FormErrorMsg;
