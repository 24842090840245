import React from "react";
import { randomAngle, randomNumber } from "../../utils/utils";

const CircleText = ({ text, textRadius = 40, innerCircle, handler }) => {
  const degToRadians = (deg) => (deg * Math.PI) / 180;

  const textChars = text.split("");
  const textDiameter = textRadius * 2;
  const randomAngleVal = randomAngle(0, 270);
  const deltaAngle = 360 / textChars.length;

  return (
    <div
      onClick={handler}
      style={
        {
          // position: "relative",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // width: textDiameter,
          // height: textDiameter,
        }
      }
      className="circle-text-container"
    >
      {/* {innerCircle && ( */}
      <div
        style={
          {
            // background: innerCircle,
            // height: textRadius / 1.6,
            // width: textRadius / 1.6,
          }
        }
        className="circle-text-inner-circle"
      ></div>
      {/* )} */}
      <div className="circle-text-chars">
        {textChars.map((char, index) => (
          <span
            style={{
              position: "absolute",
              transformOrigin: "top left",
              top:
                textRadius * -Math.cos(degToRadians(index * deltaAngle)) +
                textRadius,
              left:
                textRadius * Math.sin(degToRadians(index * deltaAngle)) +
                textRadius,
              transform: `rotate(${index * deltaAngle}deg)`,
            }}
            className="circle-char"
          >
            {char}
          </span>
        ))}
      </div>
    </div>
  );
};

export default CircleText;
